import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/webdesign01.jpg'
import pic02 from '../images/accounting01.jpg'
import pic03 from '../images/tools.png'
import pic04 from '../images/joonised01.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="veeb"
          className={`${this.props.article === 'veeb' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Veebilehed</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Kiired ja turvalised JAMSTACK-veebilehed.
            Odav veebimajutus,
            kiire laadimine,
            alati mobiilisõbralikud.
            Tulevad koos sisseehitatud SEO ja GDPR-sobiliku Analyticsiga.
            Rohkem infot: 
          </p>
          <p>
          <a target="_blank" rel="noopener noreferrer" href="https://leht.eu"><button>leht.eu</button></a>
          </p>
          {close}
        </article>

        <article
          id="rent"
          className={`${this.props.article === 'rent' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Tööriistarent</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Tööriistade renti pakub meil:
          </p>
          <p>
          <a target="_blank" rel="noopener noreferrer" href="https://rendifirma.ee"><button>Rendifirma</button></a>
          </p>
          {close}
        </article>

        <article
          id="joonised"
          className={`${this.props.article === 'joonised' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Joonestamine</h2>
          <span className="image main">
            <img src={pic04} alt="" />
          </span>
          <p>
            Joonestusteenused ideest kuni valmis ja värvitud masinani välja.
            Kõik on tehtav, vaja lihtsalt kusagilt alustada.
          </p>
          <p>
          Küsi infot: <a href="mailto:avalik@lehtmetall.ee"><strong>avalik@lehtmetall.ee</strong></a>
          </p>
          {close}
        </article>

        <article
          id="raamat"
          className={`${this.props.article === 'raamat' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Raamatupidamine</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Pakume raamatupidamisteenust ja teeme majandusaasta aruandeid väiksematele ettevõtetele ja korteriühistutele.

          </p>
          <p>
            Rohkem infot: <a href="mailto:rmp@lehtmetall.ee"><strong>rmp@lehtmetall.ee</strong></a>
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <p>
            <ul>
              <li>+372 5626 4431</li>
              <li><a href="mailto:avalik@lehtmetall.ee">avalik@lehtmetall.ee</a></li>
              <li>Leht metall OÜ</li>
              <li>Reg. nr: 14281422</li>
              <li>Viti küla, Harku vald, Harjumaa</li>
              <li>lehtmetall.ee</li>
            </ul>
          </p>
          <p>Gatsby starteri leiab allolevatele ikoonidele vajutades:</p>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

      
        {/* <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article> */}
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
